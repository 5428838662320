/* Header section  */

.title-bar {
  padding: 1rem 0.5rem !important;
  background: #00332e !important;
  color: #fffde6 !important;
}

@media (max-width: 39.99875em) {
  .title-bar {
    border-top: none !important;
  }
}

.omnipresent-banner,
.omnipresent-banner-title {
  font-weight: bold;
  margin-right: 1em;
  color: #fffde6 !important;
}


.omnipresent-banner-short-description {
  margin-right: 0.5em;
  margin-left: 1em;
  color: #fffde6 !important;  /* Color shared with title */
}


.omnipresent-banner a {
  color: #fffde6 !important;
}

.topbar__search input {
  background: #fffde6 !important;
}

.topbar__search:not(:focus-within) button {
  background: #367e6a !important;
}

input::placeholder {
  color: #00332e !important;
}

.topbar__search:not(:focus-within) button {
  background-color: rgba(255, 255, 255, 0.02) !important;
  color: rgb(1, 36, 9) !important;
}

.topbar__user__login a {
  color: #fffde6 !important;
  font-weight: 600 !important;
}

.navbar {
  background: #367e6a !important;
}

.main-nav__link a {
  padding: 0.75em 2em !important;
  font-weight: 600 !important;
  color: #fffde6 !important;
}

.main-nav__link a:hover {
  background: #fffde6 !important;
  color: #00332e !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.off-canvas {
  background-color: #367e6a !important;
}

.off-canvas .close-button {
  color: #fffde6 !important;
  padding: 0.2rem 0.5rem !important;
  margin-right: -0.5rem !important;
}

.process-nav {
  background-color: #367e6a !important;
  padding: 0.75rem 1rem !important;
  box-shadow: inset 0 -3px 0 0 #e8e8e8 !important;
}

.process-nav__link {
  text-transform: uppercase !important;
  color: #fffde6 !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
}

.process-nav__link:hover {
  color: #fffde6 !important;
  text-decoration: underline !important;
}

.process-header__phase, .process-phase {
  background: #fffde6 !important;
  padding: 1rem !important;
  margin: 1rem 0 !important;
  border-radius: 4px !important;
}

@media print, screen and (min-width: 40em) {
  .hero-heading {
    font-size: calc(1.5rem + ((100vw - 640px) / 20));
  }
}

.hero-heading {
  margin-bottom: 2.5rem;
  font-size: 3.5rem !important;
  line-height: 1;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

/* Body section  */

body {
  margin: 0 !important;
  padding: 0 !important;
  background: #fffde6 !important;
  font-family: "Source Sans Pro", Helvetica, Roboto, Arial, sans-serif !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  color: #00332e !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

h1.text-highlight.heading1.highligted-content-banner-heading {
  margin-bottom: 0 !important;
  color: #fffde6 !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8) !important;
}

.text-highlight {
  margin-bottom: 0 !important;
  color: #fffde6 !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8) !important;
}

.button {
  background-color: #fffde6 !important;
  color: #00332e !important;
  border: 1px solid #00332e !important;
}

.button:hover, .meeting-polls__button:hover, .button:focus, .meeting-polls__button:focus {
  text-decoration: underline !important;
  color: #00332e !important;
  border: 1px solid #00332e !important;
}

.button.hollow, .meeting-polls__button, .button.hollow.disabled, .button.hollow[disabled], .button.hollow.disabled:hover, .button.hollow[disabled]:hover, .button.hollow.disabled:focus, .button.hollow[disabled]:focus {
  color: #00332e !important;
  border: 1px solid #00332e !important;
}

.wrapper {
  padding: 3rem 1.5rem !important;
  background-color: #fffde6 !important;
}

.mini-title {
  color: #00332e !important;
  font-size: 0.9rem !important;
  letter-spacing: 0.01em !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

.data-picker.picker-single .picker-values div a,
.data-picker .picker-prompt a {
  background: #fffde6 !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  padding: 0.4rem 0.7rem !important;
  outline: 0 !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 4px !important;
  box-shadow: inset 0 1px 2px rgba(26, 24, 29, 0.1) !important;
  -webkit-box-shadow: inset 0 1px 2px rgba(26, 24, 29, 0.1) !important;
  color: #00332e !important;
}

select,
.inline-filters button[data-toggle] {
  height: 2.4375rem !important;
  margin: 0 0 1rem !important;
  padding: 0.5rem !important;
  appearance: none !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 4px !important;
  background-color: #fffde6 !important;
  font-family: inherit !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  color: #00332e !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb(44, 41, 48)'></polygon></svg>") !important;
  background-origin: content-box !important;
  background-position: right -1rem center !important;
  background-repeat: no-repeat !important;
  background-size: 9px 6px !important;
  padding-right: 1.5rem !important;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out !important;
}

section#highlighted_processes {
  background-color: #fffde6 !important;
}

.home-section:nth-of-type(2n + 1) {
  background-color: #f7e8d1 !important;
}

.callout.announcement {
  margin-bottom: 1em;    background-color: #f7e8d1 !important;
}

a:hover {
  color: #367e6a !important;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

a {
  color: #367e6a !important;
  font-weight: normal;
}

/* Card section  */

.card {
  margin-bottom: 30px !important;
  background: #00332e !important;
  border: 1px solid #fffde6 !important;
  border-radius: 4px !important;
  overflow: visible !important;
  overflow-wrap: break-word !important;
  hyphens: auto !important;
}

.card__link {
  color: #fffde6 !important;
}

.card__link:hover {
  color: #fffde6 !important;
  text-decoration: underline !important;
}

.card--process__small {
  font-size: 90% !important;
  color: #fffde6 !important;
}

.card-data__item {
  display: inline-flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  flex-basis: 33.33% !important;
  flex-grow: 1 !important;
  text-align: center !important;
  font-size: 0.9375rem !important;
  line-height: 1 !important;
  color: #fffde6 !important;
  padding: 0.5em !important;
  border-right: 1px solid #fffde6 !important;
  margin-right: -1px !important;
  border-top: 1px solid #fffde6 !important;
  margin-top: -1px !important;
}

.card__text--paragraph {
  color: #fffde6 !important;
}
.card__footer,
.card__top {
  background: #00332e !important;
  border-top: 1px solid #fffde6 !important;
  border-radius: 0 0 4px 4px !important;
  clear: both !important;
}

.card__support__data--left {
  text-align: left !important;
  text-transform: none !important;
  margin-bottom: 0 !important;
  color: #fffde6 !important;
  max-width: 60% !important;
}

.definition-data__title {
  display: block !important;
  font-size: 85% !important;
  letter-spacing: 0.01em !important;
  text-transform: uppercase !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  color: #fffde6 !important;
}

.definition-data__item {
  display: block;
  padding: 0.75rem 0.5rem !important;
  border-bottom: 1px solid #eadada !important;
  color: #fffde6 !important;
  flex-basis: 100%;
}

.card.extra.definition-data {
  color: #fffde6 !important;
}

.card-data__item.creation_date_status strong {
  text-transform: uppercase  !important;
  font-weight: 600  !important;
  font-size: 0.8em  !important;
  letter-spacing: 0.05em  !important;
  color: #fffde6 !important;
}

.card__icondata ul li:not(:first-child) strong, .card__block ul li:not(:first-child) strong {
  text-transform: uppercase  !important;
  font-weight: 600  !important;
  font-size: 0.8em  !important;
  letter-spacing: 0.05em  !important;
  color: #fffde6 !important;
}

.tech-info {
  color: #00332e !important;
  font-size: 0.9rem !important;
  text-align: center !important;
}

.text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 1.5 !important;
  color: #fffde6 !important;
}

.ql-editor-display {
  color: #00332e !important;
}

.collapsible-list .card__text--paragraph {
  text-decoration: none;
  color: #fffde6 !important;
}

/* Process section  */

section-heading {
  position: relative !important;
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
  font-size: 1.125em !important;
  letter-spacing: 0.05em !important;
  text-transform: uppercase !important;
  color: #fffde6 !important;
}

.process-header__phase, .process-phase {
  background: #fffde6 !important;
  padding: 1rem !important;
  margin: 1rem 0 !important;
  border-radius: 4px !important;
}

.process-nav {
  background-color: #367e6a !important;
  padding: 0.75rem 1rem !important;
  box-shadow: inset 0 -3px 0 0 #e8e8e8 !important;
}

.process-nav__link {
  color: #fffde6 !important;
}

.process-nav__link:hover {
  text-decoration: underline !important;
  color: #fffde6 !important;
}

.process-nav__hidden-content__more {
  background-color: #fffde6 !important;
  text-align: right;
  border-bottom: 1px solid #e8e8e8;
  padding: 0.5rem 1rem;
}

/* Pages section  */

.tabs {
  margin: 0;
  border: 1px solid transparent !important;
  background: #f7e8d1 !important;
  list-style-type: none !important;
}

.side-panel {
  background-color: #f7e8d1 !important;
  min-height: 100% !important;
  border-bottom: 1px solid #e8e8e8 !important;
}

.tabs-content {
  background: #fffde6 !important;
  color: inherit !important;
  transition: all 0.5s ease !important;
}

.main-container__content {
  background-color: #fffde6 !important;
}

.callout.secondary {
  background-color: #f7e8d1 !important;
  color: #00332e !important;
}


/* Debate section  */

.filters__section, .filters__section--general {
  border-bottom: 1px solid #fffde6!important;
  padding: 1rem 1rem !important;
  color: #fffde6 !important;
}

.card__text--paragraph {
  color: #fffde6 !important;
}

.filters__section label, .filters__section--general label {
  line-height: 1.2 !important;
  margin-bottom: 0.4rem !important;
  color: #fffde6 !important;
}

.card-data__item.creation_date_status strong {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  letter-spacing: 0.05em !important;
  color: #fffde6 !important;
}

.card__icondata ul li:not(:first-child) strong, .card__block ul li:not(:first-child) strong {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  letter-spacing: 0.05em !important;
  color: #fffde6 !important;
}

.has-tip {
  position: relative !important;
  display: inline-block !important;
  border-bottom: 0 !important;
  font-weight: normal !important;
  color: #fffde6 !important;
}

input#filters_24818640-ad11-4ab8-8926-b2f5e5e30c62_filter_search_text_cont {
  background-color: #fffde6 !important;
  color: #00332e !important;
}

.callout.announcement {
  margin-bottom: 1em !important;
  background-color: #f7e8d1 !important;
}

.collapsible-list .card__text--paragraph {
  text-decoration: none;
  color: #fffde6 !important;
}

/* Footer section  */

.main-footer {
  background-color: #367e6a !important;
  position: relative !important;
  padding: 1rem 0 !important;
  min-height: 76px !important;
}

.main-footer a:hover,
.main-footer.mini-footer a:hover {
  color: #fffde6 !important;
  text-decoration: underline !important;
}

.main-footer a,
.main-footer.mini-footer a {
  color: #fffde6 !important;
}

.mini-footer {
  padding: 1rem 0 !important;
  background-color: #00332e !important;
}

/*  Backend section  */

label {
  display: block !important;
  margin: 0 !important;
  font-size: 0.875rem !important;
  font-weight: normal !important;
  line-height: 1.8 !important;
  color: #fffde6 !important;
}

.table-list {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  color: #fffde6 !important;
}

table.table-list.users_count {
  color: #fffde6 !important;
}

.table-list a:not(.button) {
  color: #fffde6 !important;
}

.help-text {
  margin-top: -0.5rem !important;
  font-size: 0.8125rem !important;
  font-style: italic !important;
  color: #fffde6 !important;
}

.ql-snow * {
  box-sizing: border-box !important;
  color: #fffde6 !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px !important;
  color: #fffde6 !important;
}

.editor-container p {
  line-height: 1.6 !important;
  margin-bottom: 1rem !important;
  text-rendering: optimizeLegibility !important;
  color: #fffde6 !important;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em !important;
  color: #fffde6 !important;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em !important;
  color: #fffde6 !important;
}

.ql-editor > * {
  cursor: text !important;
  color: #fffde6 !important;
}

.active-uploads {
  color: #fffde6 !important;
}

.mb-m {
  margin-bottom: 3rem !important;
  color: #fffde6 !important;
}

.card-section {
  flex: 1 0 auto !important;
  padding: 1rem !important;
  color: #fffde6 !important;
}

.table-scroll {
  overflow-x: auto !important;
  color: #fffde6 !important;
}

.logs .logs__log__explanation {
  flex-grow: 34 !important;
  flex-basis: 1px !important;
  color: #fffde6 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
  background-color: #00332e !important;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
  background-color: #fffde6 !important;
}

button.ql-linebreak {
  color: #00332e !important;
}
